
.main-text-section {
  width: 80%;
  margin: 10px auto 0;
  text-align: center;

  @include breakpoint-min(md) {
    margin-top: 30px;
  }

  @include breakpoint-ipad-portrait {
    margin-top: 40px;
  }

  @include breakpoint-iphone-x {
    margin-top: 3vh;
    margin-bottom: 4vh;
  }

  &--thank-you-page {
    margin-top: 5px;

    @include breakpoint-min(md) {
      width: 100%;
      margin-top: 10px;
    }
  }

  &__text {
    text-shadow: 2px 1px rgba($color-black, .1);

    &--thank-you-page {
      font-size: 16px;
      line-height: 17px;

      @include breakpoint-min(xs) {
        font-size: 18px;
        line-height: 21px;
        margin-top: 9px;

      }

      @include breakpoint-min(md) {
        font-size: 24px;
        line-height: 28px;
        margin-top: 25px;
      }

      @include breakpoint-iphone-x {
        font-size: 22px;
        line-height: 25px;
      }
    }
  }
}
