
.animation {
  &--slide-left {
    animation: slideLeft 250ms linear forwards;
  }

  &--slide-in {
    animation: slideIn 250ms linear forwards;
  }

  &--shake {
    animation: shake 1s both;
    position: relative;
  }
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }

}

@keyframes slideIn {
  100% {
    transform: translateX(0);
  }

}

@keyframes slideLeft {

  100% {
    transform: translateX(-200vw);

    @include breakpoint-min(md) {
      transform: translateX(-100vw);
    }
  }
}
