
.btn {
  appearance: none;
  border-radius: 20px;
  height: 50px;
  display: inline-block;
  font-size: 25px;
  color: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;
  text-decoration: none;
  line-height: 45px;
  outline: none;

  @include breakpoint-min(md) {
    height: 60px;
    line-height: 55px;
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  &--background {
    box-shadow: 3px 2px 0 1px rgba($color-black, .1);

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -3px;
      background: $color-white;
      width: 105%;
      height: 105%;
      border-radius: 20px;
      z-index: -1;
      transform: rotate(-2deg);
    }
  }

  &--primary {
    background: $color-primary;
  }

  &--secondary {
    background: $color-white;
    color: $color-primary;
    border-color: $color-primary;
  }

  &--sm {
    width: 45%;
    margin: 0;
  }
}

.enter-button {
  text-align: center;
}
