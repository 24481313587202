
.purchase-again {
  background: $color-white;
  height: 60px;
  line-height: 60px;
  border-radius: 20px;
  padding: 0 30px;
  position: relative;
  box-shadow: 3px 3px 0 2px rgba($color-black, .3);

  @include breakpoint-min(xs) {
    height: 90px;
    line-height: 90px;
  }

  &:first-of-type {
    margin-bottom: 30px;
  }

  &__container {
    width: 85%;
    margin: 20px auto;

    @include breakpoint-min(xs) {
      margin-bottom: 60px;
      margin: 30px auto;
    }

    @include breakpoint-iphone-x {
      margin-bottom: 8vh;
    }
  }

  &__label {
    color: $color-primary;
    font-weight: 700;
    font-size: 35px;
    min-width: 90px;
    display: inline-block;
    text-transform: uppercase;

    @media screen and (min-width: 410px) {
      min-width: 140px;
    }

    @include breakpoint-min(md) {
      min-width: 250px;
    }
  }

  &__checkbox {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    appearance: none;
    border: 3px solid $color-primary;
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3vh;
    outline: 0;

    &:checked {
      background: $color-primary;
      box-shadow: inset 0 0 0 3px  $color-white;
    }
  }
}
