
// sass-lint:disable no-vendor-prefixes

.modal {
  background: $color-primary;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 200;
  width: 90vw;
  // height: 90vh;
  max-height: 90vh;
  opacity: 0;
  display: none;
  padding: 4px;
  text-align: center;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  @include breakpoint-min(md) {
    width: 60vh;
    // height: auto;
  }

  ::-webkit-scrollbar {
    width: 30px;
    padding-right: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-primary;
    border-radius: 18px;
    background-clip: padding-box;
    border: 9px solid transparent;
    height: 50px;
  }

  &--show {
    display: block;
    animation: modalFadeIn 250ms forwards;
  }

  &--hide {
    animation: modalFadeOut 250ms forwards;
  }

  &__content {
    width: 100%;
    background: $color-white;
    border-radius: 10px;
    position: relative;
  }

  &__title {
    color: $color-black;
    font-weight: 700;
    font-size: 25px;
    padding-top: 20px;
    letter-spacing: 0;
    margin-bottom: 20px;
  }

  &__text-container {
    text-align: left;
    padding: 0 20px 20px;
    max-height: 50vh;
    overflow-y: scroll;
    position: relative;

    @include breakpoint-min(md) {
      padding-bottom: 0;
    }
  }

  &__text {
    color: $color-black;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 200;
    margin-bottom: 20px;
    line-height:20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__button-container {
    display: flex;
    padding: 20px;
    justify-content: space-between;
  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 190;
    display: none;

    &--show {
      display: block;
      animation: backdropFadeIn 250ms linear forwards;
    }
  }
}

@keyframes backdropFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes modalFadeIn {
  0% {
    display: block;
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes modalFadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}
