
// Font Weights
// Regular: 400
// Bold: 700
// Medium: 500

body {
  font-size: 17px;
  font-weight: 400;
  color: $color-white;
  font-family: 'ubuntu';
}

input {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 800;
  letter-spacing: .5px;
}

h1 {
  font-size: 3rem;
  line-height: 3.1rem;
  text-transform: uppercase;
}

h2 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;

  @include breakpoint-min(xs) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  @include breakpoint-min(md) {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  @include breakpoint-ipad-portrait {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

p {
  margin: 0;
}

.text-left{
  text-align:left!important;
}
