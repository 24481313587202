
.thanks-banner {
  background: $color-primary;
  width: 100%;
  text-align: center;
  padding: 0 0 10px;
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  @include breakpoint-min(md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include breakpoint-min(lg) {
    margin: 4vh 0 0;
  }

  @include breakpoint-ipad-portrait {
    margin-top: 20px;
  }

  @include breakpoint-iphone-x {
    margin-top: 3vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  &::after {
    content: '';
    width: 110%;
    height: 100%;
    background: $color-primary-dark;
    transform: rotate(-2deg) translateX(-50%);
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: -1;

    @include breakpoint-min(md) {
      transform: rotate(-.5deg) translateX(-50%);
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 25px;
    line-height: 30px;

    @include breakpoint-min(xs) {
      font-size: 30px;
      line-height: 35px;
    }

    @include breakpoint-min(md) {
      font-size: 35px;
      line-height: 40px;
    }

    @include breakpoint-iphone-x {
      padding-bottom: 1vh;
    }
  }

  &__sub-title {
    font-size: 18px;
    line-height: 20px;
    width: 70%;
    margin: 0 auto;

    @include breakpoint-min(xs) {
      font-size: 20px;
      line-height: 22px;
    }
  }
}
