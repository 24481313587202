
.landing-banner {
  position: relative;
  background-color: $color-primary;
  width: 100%;
  border-top: 9px solid $color-white;
  border-bottom: 9px solid $color-white;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 10vh;
  background-image: url('../images/shared/Banner-Effects.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 2vh;

  @include breakpoint-min(sm) {
    margin-top: 10px;
  }

  @include breakpoint-min(md) {
    margin-top: 5vh;
    margin-bottom: 12vh;
  }

  @include breakpoint-iphone-x {
    margin-bottom: 12vh;
    margin-top: 5vh;
  }

  &__image-text {
    width: 30vh;
    margin: 0 auto;

    @include breakpoint-min(xs) {
      width: 40vh;
    }
  }

  &__image-prizes {
    position: absolute;
    bottom: -42px;
    left: 50%;
    transform: translateX(-50%) scale(1.1);

    @include breakpoint-min(sm) {
      bottom: -70px;
      transform: translateX(-50%) scale(.7);
    }

    @include breakpoint-min(sm) {
      bottom: -74px;
    }
  }

  &__terms-text {
    position: absolute;
    left: 50%;
    bottom: -53px;
    display: inline-block;
    transform: translateX(-50%) rotate(-2deg);
    font-size: 11px;
    font-weight: 500;

    @include breakpoint-min(sm) {
      bottom: -70px;
    }
  }
}
