.form-group {
  margin-bottom: 6px;

  @include breakpoint-iphone-x {
    margin-bottom: 2vh;
  }

  @include breakpoint-min(md) {
    margin-bottom: 2vh;
  }
}

.form-section {
  position: relative;

  @media screen and (min-width: 410px) {
    margin-top: 30px;
  }

  @include breakpoint-min(md) {
    margin-top: 40px;
  }

  &--checkbox {
    line-height: 20px;
    text-align: center;

    @media screen and (min-width: 410px) {
      margin: 0px auto 9px;
    }

    @include breakpoint-min(lg) {
      margin: 1vh 0;
    }

    @include breakpoint-ipad-portrait {
      margin: 30px 0 40px;
    }

    @include breakpoint-iphone-x {
      margin: 3vh 0;
    }
  }

  &__button{
    width: 100%;
    height: 5vh;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    margin-bottom: 3px;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: #fff;
    outline: 0;
    background-color: #0099cc;
  }
  
  &__input {
    appearance: none;
    width: 100%;
    border-radius: 5px;
    height: 35px;
    border: 0;
    margin-bottom: 3px;
    box-shadow: 2px 2px 0 0 rgba($color-black, .2);
    padding-left: 20px;
    font-size: 16px;
    color: $color-primary;
    outline: 0;

    @media screen and (min-width: 410px) {
      height: 50px;
    }

    @include breakpoint-min(md) {
      font-size: 18px;
    }

    @include breakpoint-iphone-x {
      height: 50px;
    }

    &::placeholder {
      color: $color-placeholder;
      font-weight: 500;
    }

    &:focus {
      box-shadow: 0 0 5px 2px $color-primary;
    }

    &--error {
      border: 3px solid $cg-blue;
    }
  }

  &__select {

    font-family:inherit;
    appearance: none;
    color: $color-primary!important;

    
  }

  &--select {
      &::after {
        @supports (display: grid) {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0;
          border-color: #000 transparent transparent;
          position: absolute;
          right: 30px;
          bottom: 18px;
          z-index: -1;
        }
      }
    }

    &--select-error {
      &::after {
        bottom: 20px;

        @include breakpoint-min(lg) {
          bottom: 40px;
        }
      }
    }

  &__span-error {
    color: $color-white;
    font-size: 2vh;
    display: none;
  }

  &__checkbox {
    appearance: none;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    background: $color-white;
    box-shadow: 2px 2px 0 0 rgba($color-black, .2);
    margin-right: 8px;

    &:checked {
      background: $color-primary;
      box-shadow: inset 0 0 0 3px $color-white;
    }
  }

  &__label {
    font-size: 16px;
    position: relative;
    top: -8px;
  }

  &__underline {
    font-size: 16px;
    color: inherit;
    text-decoration: underline;
  }

  &__label_checkbox{
    margin-left: 38px;
    display: block;
    margin-top: -20px;
    
  }

  &__label_checkbox::before {
    margin-left: -35px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    
  }


}
