
.vip-pass {
  position: relative;
  left: 50%;
  top: 98%;
  transform: translateX(-50%);

  @include breakpoint-min(xs) {
    margin-bottom: 4vh;
  }

  &__image {
    width: 21vh;
    position: relative;
    left: 50%;
    top: -3px;
    transform: translateX(-50%);

    @include breakpoint-min(xs) {
      width: 22vh;
    }

    @include breakpoint-min(md) {
      width: 30vh;
    }

    @include breakpoint-min(lg) {
      width: 25vh;
    }
  }
}
