
// Color Names
$rich-electric-blue: #09c;
$cg-blue:            #017ba4;
$wild-watermelon:    #fc7b84;
$saffron:            #f8c23e;
$davys-gray:         #535353;

$color-white: #fff;
$color-black: #000;

$color-primary: $rich-electric-blue;
$color-primary-dark: $cg-blue;
$color-placeholder: $davys-gray;

$color-text-primary: $color-white;
$color-text-secondary: $color-black;

$color-error-orange: #f6cf40;
