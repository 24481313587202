
.logo {
  text-align: center;
  padding-top: 3vh;

  @include breakpoint-min(sm) {
    padding-top: 20px;
  }

  @include breakpoint-ipad-portrait {
    padding-top: 50px;
  }

  @include breakpoint-iphone-x {
    padding-top: 50px;
  }

  &--thanks {
    padding-top: 10px;

    @include breakpoint-min(xs) {
      padding-top: 20px;
      margin-top: 2vh;
    }
  }

  &__image {
    width: 15vh;

    @include breakpoint-ipad-pro {
      width: 25vh;
    }

    @include breakpoint-iphone-x {
      width: 20vh;
    }

    @include breakpoint-min(lg) {
      width: 25vh;
    }

    &--landing {
      width: 20vh;

      @include breakpoint-iphone-x {
        width: 25vh;
      }

      @include breakpoint-min(md) {
        width: 25vh;
        padding-top: 3vh;
      }

      @include breakpoint-min(lg) {
        padding-top: 1vh;
      }
    }
  }
}

.britvic-logo {
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint-min(sm) {
    bottom: 15px;
  }

  &__logo {
    width: 13vh;
  }
}

.sub-logo {
  position: relative;
  margin-top: 20px;
  text-align: center;

  &__logo {
    width: 13vh;
  }
}

